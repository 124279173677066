var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "system-info-wrapper" }, [
    _vm.info
      ? _c("div", { staticClass: "some-info" }, [
          _c("p", { staticClass: "host" }, [
            _vm._v(
              " " +
                _vm._s(_vm._f("isUsername")(_vm.info.username)) +
                _vm._s(_vm.info.hostname) +
                " "
            ),
          ]),
          _c("p", { staticClass: "system" }, [
            _vm._v(" " + _vm._s(_vm.info.system) + " "),
            _c("span", { staticClass: "gap" }, [_vm._v("|")]),
            _vm._v(
              " " +
                _vm._s(_vm.$t("widgets.system-info.uptime")) +
                ": " +
                _vm._s(_vm._f("makeUptime")(_vm.info.uptime)) +
                " "
            ),
          ]),
        ])
      : _vm._e(),
    _c("div", { staticClass: "some-charts" }, [
      _c("div", {
        staticClass: "mem-chart",
        attrs: { id: `memory-${_vm.chartId}` },
      }),
      _c("div", {
        staticClass: "load-chart",
        attrs: { id: `load-${_vm.chartId}` },
      }),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }